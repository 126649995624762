import { encode } from "./helpper";
import { getBranchID } from "../hooks/user";
import { API, isDealer } from "../hooks/api";
import axios from "axios";
import useLiff from "../hooks/useAuth";
import ErrorPage from "../components/ErrorPage";
import { useState } from "react";

const VespaCallBack = () => {
  const { getProfile, getAccessToken } = useLiff();
  const updateVespaID = async () => {
    const token = getAccessToken;
    const profile = getProfile;
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const branchId = getBranchID();

    const param = {
      accesstoken: token,
      lineuserid: profile.userId,
      ...(isDealer && branchId && { branchid: branchId }),
      code: code,
    };
    const encodedData = encode(param);

    const { data: connectVespaData } = await axios.post(
      API.CONNECT_VESPA_ID,
      encodedData,
    );

    if (connectVespaData?.result === "complete") {
      if (connectVespaData.isnewuser) {
        window.location = `/my-profile?branchId=${branchId}`;
        return null;
      } else {
        window.location = `/privileges?branchId=${branchId}`;
        return null;
      }
    }
  };

  updateVespaID();

  return null;
};

export default VespaCallBack;
