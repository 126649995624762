import React from "react";
import { makeStyles } from "@material-ui/core";
import BackGroundImg from "../images/background2.png";

const useStyles = makeStyles({
  container: {
    backgroundImage: `url(${BackGroundImg})`,
    backgroundPosition: "0% 0%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto",
    minHeight: "100vh",
  },
});

const AppBarNone = () => {
  const classes = useStyles();
  return <div className={classes.container}></div>;
};

export default AppBarNone;
