import React, { useEffect, useState } from "react";
import AppBar from "../components/AppBar";
import AppBarNone from "../components/AppBarNone";
import Footer from "../components/Footer";
import "../App.css";
import muiStyles from "./muiStyle";
import useLiff from "../hooks/useAuth";
import axios from "axios";
import { API, isDealer } from "../hooks/api";
import { availableMonths, encode, humanExpireTime } from "./helpper";
import { useHistory, useParams } from "react-router-dom";
import { getMyCoupons, useAPIQuery } from "../hooks/queries";
import { getBranchID } from "../hooks/user";

const MyUsedCouponDetail = () => {
  const classes = muiStyles();
  const history = useHistory();
  const { id } = useParams();
  const [purchaseDate, setPurchaseDate] = useState();
  const [serviceDate, setServiceDate] = useState();
  const { getProfile, getAccessToken } = useLiff();
  const token = getAccessToken;
  const profile = getProfile;

  const branchId = getBranchID();

  const param = {
    accesstoken: token,
    lineuserid: profile.userId,
    ...(isDealer && { dealerid: branchId }),
  };

  const encodedData = encode(param);
  const myCoupons = useAPIQuery("myCoupons", () => getMyCoupons(encodedData));
  const couponList = myCoupons?.data?.items ?? [];
  const data = couponList.find((f) => f.code === id);

  useEffect(() => {
    if (data?.redeeminfo?.redeempurchase) {
      var redeemdate = data?.redeeminfo?.redeempurchase;
      var date = parseInt(redeemdate.split("-")[2]);
      var month = parseInt(redeemdate.split("-")[1]);
      var year = parseInt(redeemdate.split("-")[0]);
      var dateFormat = `${date} ${availableMonths[month - 1].th} ${(year || 0) + 543}`;
      setPurchaseDate(dateFormat);
    }

    if (data?.redeeminfo?.redeemcouponserviceuse) {
      var redeemdate = data?.redeeminfo?.redeemcouponserviceuse;
      var date = parseInt(redeemdate.split("-")[2]);
      var month = parseInt(redeemdate.split("-")[1]);
      var year = parseInt(redeemdate.split("-")[0]);
      var dateFormat = `${date} ${availableMonths[month - 1].th} ${(year || 0) + 543}`;
      setServiceDate(dateFormat);
    }
  }, [data]);

  return (
    <>
      <div className={classes.container}>
        <AppBar />

        <div className={classes.content}>
          <div className={classes.wrapper}>
            <div className={classes.headingPath}>
              <a
                onClick={() =>
                  history.push(`/my-used-coupon?branchId=${branchId}`)
                }
                className={classes.headingBack}
              ></a>
              <div className={classes.headingText}>{data?.titleth || ""}</div>
            </div>

            <div className={classes.coupon}>
              <div className={classes.coupons}>
                <div className={classes.couponImageWrap}>
                  <img
                    className="coupon__image coupon__anchor--expired"
                    src={data?.assetrectangle}
                  />

                  <div className={classes.couponCodeWrap}>
                    <div className={classes.couponCode}>
                      {`No. ${data?.code || ""}`}
                    </div>
                  </div>
                </div>

                {data?.activatedate !== "" && (
                  <div className={classes.couponFooter}>
                    <div className={classes.couponContentFooter}>
                      {" "}
                      {`ใช้คูปองวันที่ ${serviceDate}`}
                    </div>
                  </div>
                )}

                <div className={classes.redeem}>
                  {data?.redeeminfo?.redeemfirstname && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>ชื่อ-นามสกุล</div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.redeemfirstname}{" "}
                        {data?.redeeminfo?.redeemlastname}
                      </div>
                    </div>
                  )}

                  {data?.redeeminfo?.redeemmobile && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>เบอร์โทรศัพท์</div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.redeemmobile}
                      </div>
                    </div>
                  )}

                  {data?.redeeminfo?.redeembranchusage && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>
                        โชว์รูม / สาขา / ศูนย์บริการ
                      </div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.redeembranchusage}
                      </div>
                    </div>
                  )}

                  {data?.redeeminfo?.redeememail && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>อีเมล</div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.redeememail}
                      </div>
                    </div>
                  )}

                  {data?.redeeminfo?.redeemaddress && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>ที่อยู่</div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.redeemaddress}
                      </div>
                    </div>
                  )}

                  {data?.redeeminfo?.redeemstreet && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>ถนน</div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.redeemstreet}
                      </div>
                    </div>
                  )}

                  {data?.redeeminfo?.redeemtumbon && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>ตำบล / แขวง</div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.redeemtumbon}
                      </div>
                    </div>
                  )}

                  {data?.redeeminfo?.redeemdistrict && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>อำเภอ / เขต</div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.redeemdistrict}
                      </div>
                    </div>
                  )}

                  {data?.redeeminfo?.redeemprovince && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>จังหวัด</div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.redeemprovince}
                      </div>
                    </div>
                  )}

                  {data?.redeeminfo?.redeempostal && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>รหัสไปรษณีย์</div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.redeempostal}
                      </div>
                    </div>
                  )}

                  {data?.redeeminfo?.redeembrandmodel && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>รุ่นรถ</div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.redeembrandmodel}
                      </div>
                    </div>
                  )}

                  {data?.type === "RewardFillAddress80" && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>
                        ของแถมที่ได้รับ
                      </div>
                      <div className={classes.redeemDetail}>
                        Premium Set (หมวกกันน็อค, หน้ากากผ้า, ผ้าพันคอ,
                        ผ้าคลุมเบาะ, พวงกุญแจ, กระเป๋าผ้า และชุดสติกเกอร์)
                      </div>
                    </div>
                  )}

                  {data?.type === "RewardFillAddress81" && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>
                        ของแถมที่ได้รับ
                      </div>
                      <div className={classes.redeemDetail}>
                        Premium Mini Set (กระเป๋าผ้า และพวงกุญแจ)
                      </div>
                    </div>
                  )}

                  {data?.type === "RewardFillAddress294" && (
                    <RewardFillAddress294 data={data} />
                  )}

                  {data?.type === "RewardFillAddress269" && (
                    <RewardFillAddress269 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress295" ||
                    data?.type === "RewardFillAddress264") && (
                    <RewardFillAddress295 data={data} />
                  )}

                  {data?.type === "RewardFillAddress48" && (
                    <RewardFillAddress48 data={data} />
                  )}

                  {data?.type === "RewardCouponWithCond5B" && (
                    <RewardCouponWithCond5B data={data} />
                  )}

                  {data?.type === "RewardFillAddress90" && (
                    <RewardFillAddress90 data={data} />
                  )}

                  {data?.type === "RewardFillAddress91" && (
                    <RewardFillAddress91 data={data} />
                  )}

                  {data?.type === "RewardFillAddress220" && (
                    <RewardFillAddress220 data={data} />
                  )}

                  {data?.type === "RewardFillAddress96" && (
                    <RewardFillAddress96 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress93" ||
                    data?.type === "RewardFillAddress98" ||
                    data?.type === "RewardFillAddress114") && (
                    <RewardFillAddress93 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress160" ||
                    data?.type === "RewardFillAddress99" ||
                    data?.type === "RewardFillAddress113" ||
                    data?.type === "RewardFillAddress171" ||
                    data?.type === "RewardFillAddress183") && (
                    <RewardFillAddress160 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress161" ||
                    data?.type === "RewardFillAddress172" ||
                    data?.type === "RewardFillAddress184") && (
                    <RewardFillAddress161 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress253" ||
                    data?.type === "RewardFillAddress239") && (
                    <RewardFillAddress253 data={data} />
                  )}

                  {data?.type === "RewardFillAddress100" && (
                    <RewardFillAddress100 data={data} />
                  )}

                  {data?.type === "RewardFillAddress101" && (
                    <RewardFillAddress101 data={data} />
                  )}

                  {data?.type === "RewardFillAddress102" && (
                    <RewardFillAddress102 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress155" ||
                    data?.type === "RewardFillAddress166") && (
                    <RewardFillAddress155 data={data} />
                  )}

                  {data?.type === "RewardFillAddress293" && (
                    <RewardFillAddress293 data={data} />
                  )}

                  {data?.type === "RewardFillAddress175" && (
                    <RewardFillAddress175 data={data} />
                  )}

                  {data?.type === "RewardFillAddress229" && (
                    <RewardFillAddress229 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress190" ||
                    data?.type === "RewardFillAddress266") && (
                    <RewardFillAddress266 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress179" ||
                    data?.type === "RewardFillAddress180" ||
                    data?.type === "RewardFillAddress186" ||
                    data?.type === "RewardFillAddress187") && (
                    <RewardFillAddress179 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress181" ||
                    data?.type === "RewardFillAddress188") && (
                    <RewardFillAddress181 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress216" ||
                    data?.type === "RewardFillAddress189") && (
                    <RewardFillAddress216 data={data} />
                  )}

                  {data?.type === "RewardFillAddress104" && (
                    <RewardFillAddress104 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress154" ||
                    data?.type === "RewardFillAddress163" ||
                    data?.type === "RewardFillAddress165") && (
                    <RewardFillAddress154 data={data} />
                  )}

                  {data?.type === "RewardFillAddress148" && (
                    <RewardFillAddress148 data={data} />
                  )}

                  {data?.type === "RewardFillAddress111" && (
                    <RewardFillAddress111 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress156" ||
                    data?.type === "RewardFillAddress164") && (
                    <RewardFillAddress156 data={data} />
                  )}

                  {data?.type === "RewardFillAddress167" && (
                    <RewardFillAddress167 data={data} />
                  )}

                  {data?.type === "RewardFillAddress168" && (
                    <RewardFillAddress168 data={data} />
                  )}

                  {data?.type === "RewardFillAddress157" && (
                    <RewardFillAddress157 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress158" ||
                    data?.type === "RewardFillAddress169") && (
                    <RewardFillAddress158 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress159" ||
                    data?.type === "RewardFillAddress170") && (
                    <RewardFillAddress159 data={data} />
                  )}

                  {data?.type === "RewardFillAddress146" && (
                    <RewardFillAddress146 data={data} />
                  )}

                  {data?.type === "RewardFillAddress131" && (
                    <RewardFillAddress131 data={data} />
                  )}

                  {data?.type === "RewardFillAddress139" && (
                    <RewardFillAddress139 data={data} />
                  )}

                  {data?.type === "RewardFillAddress147" && (
                    <RewardFillAddress147 data={data} />
                  )}

                  {data?.type === "RewardCouponWithCond5D" && (
                    <RewardCouponWithCond5D data={data} />
                  )}

                  {data?.type === "RewardFillAddress267" && (
                    <RewardFillAddress267 data={data} />
                  )}

                  {data?.type === "RewardFillAddress268" && (
                    <RewardFillAddress268 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress247" ||
                    data?.type === "RewardFillAddress248" ||
                    data?.type === "RewardFillAddress274" ||
                    data?.type === "RewardFillAddress275") && (
                    <RewardFillAddress247 data={data} />
                  )}

                  {data?.type === "RewardFillAddress233" && (
                    <RewardFillAddress233 data={data} />
                  )}

                  {data?.type === "RewardFillAddress291" && (
                    <RewardFillAddress291 data={data} />
                  )}

                  {data?.type === "RewardFillAddress297" && (
                    <RewardFillAddress297 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress240" ||
                    data?.type === "RewardFillAddress254" ||
                    data?.type === "RewardFillAddress265" ||
                    data?.type === "RewardFillAddress284" ||
                    data?.type === "RewardFillAddress285" ||
                    data?.type === "RewardFillAddress286" ||
                    data?.type === "RewardFillAddress299") && (
                    <RewardFillAddress240 data={data} />
                  )}

                  {data?.type === "RewardFillAddress298" && (
                    <RewardFillAddress298 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress197" ||
                    data?.type === "RewardFillAddress223") && (
                    <RewardFillAddress223 data={data} />
                  )}

                  {(data?.type === "RewardFillAddress50" ||
                    data?.type === "RewardFillAddress51") && (
                    <RewardFillAddress50 data={data} />
                  )}

                  {data?.type === "RewardFillAddress292" && (
                    <RewardFillAddress292 data={data} />
                  )}

                  {data?.type === "RewardFillAddress234" && (
                    <RewardFillAddress234 data={data} />
                  )}

                  {data?.type === "RewardFillAddress221" && (
                    <RewardFillAddress221 data={data} />
                  )}

                  {data?.type === "RewardFillAddress249" && (
                    <RewardFillAddress249 data={data} />
                  )}

                  {data?.type === "RewardFillAddress262" && (
                    <RewardFillAddress262 data={data} />
                  )}

                  {data?.type === "RewardFillAddress261" && (
                    <RewardFillAddress261 data={data} />
                  )}

                  {data?.type === "RewardFillAddress263" && (
                    <RewardFillAddress263 data={data} />
                  )}

                  {data?.type === "RewardFillAddress213" && (
                    <RewardFillAddress213 data={data} />
                  )}

                  {(data?.itemid === "B16D9283-4267-4EB6-B172-7A88FAE6C16E" ||
                    data?.itemid ===
                      "9C617633-1BA5-48CD-A40C-4E2EB4D9D993") && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>
                        ของแถมเพิ่มเติม
                      </div>
                      <div className={classes.redeemDetail}>PICNIC SET*,</div>
                      <div className={classes.redeemDetail}>
                        ชุด Vespa Welcome Kit*,
                      </div>
                      <div className={classes.redeemDetail}>
                        ประกัน COVID-19*,
                      </div>
                      <div className={classes.redeemDetail}>
                        Vespa Home Delivery บริการจัดส่งรถถึงบ้าน
                      </div>
                    </div>
                  )}

                  {data?.itemid === "F5BF66A1-5B58-46E7-8F57-2760C0D8E78B" && (
                    // formtype26, 22-30Nov
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>
                        ของแถมเพิ่มเติม
                      </div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.param1value === "set1" && (
                          <>
                            &bull; Voucher 2,000 บาท
                            <br />
                            &bull; Vespa Voyager Pack
                            <br />
                            (กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็ดหน้า)
                            <br />
                            &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
                            <br />
                            &bull; Vespa Welcome Kit
                          </>
                        )}

                        {data?.redeeminfo?.param1value === "set2" && (
                          <>
                            &bull; Voucher 2,000 บาท
                            <br />
                            &bull; กันชนบังโคลนหน้าสีดำ
                            <br />
                            &bull; Vespa Voyager Pack
                            <br />
                            (กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็ดหน้า)
                            <br />
                            &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
                            <br />
                            &bull; Vespa Welcome Kit
                          </>
                        )}

                        {data?.redeeminfo?.param1value === "set3" && (
                          <>
                            &bull; Voucher 2,000 บาท
                            <br />
                            &bull; หมวกกันน็อค Bumshaker
                            <br />
                            &bull; Vespa Voyager Pack
                            <br />
                            (กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็ดหน้า)
                            <br />
                            &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
                            <br />
                            &bull; Vespa Welcome Kit
                          </>
                        )}

                        {data?.redeeminfo?.param1value === "set4" && (
                          <>
                            &bull; Vespa Voyager Pack
                            <br />
                            (กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็ดหน้า)
                            <br />
                            &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
                            <br />
                            &bull; Vespa Welcome Kit
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {data?.itemid === "6F7B931C-ED00-4571-95D1-66479234C9C5" && (
                    // formtype26, 22-30Nov
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>
                        ของแถมเพิ่มเติม
                      </div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.param1value === "set1" && (
                          <>
                            &bull; Voucher 2,000 บาท
                            <br />
                            &bull; Vespa Voyager Pack
                            <br />
                            (กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็ดหน้า)
                            <br />
                            &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
                            <br />
                            &bull; Vespa Welcome Kit
                            <br />
                            &bull; Servizio Package
                            <br />
                            &bull; ชุดแต่ง Accessories รุ่น GTS Super Sport 300
                            HPE
                          </>
                        )}

                        {data?.redeeminfo?.param1value === "set2" && (
                          <>
                            &bull; Voucher 2,000 บาท
                            <br />
                            &bull; Vespa Voyager Pack
                            <br />
                            (กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็ดหน้า)
                            <br />
                            &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
                            <br />
                            &bull; Vespa Welcome Kit
                            <br />
                            &bull; Servizio Package
                            <br />
                            &bull; ชุดแต่ง Accessories รุ่น GTS Super Tech 300
                            HPE
                          </>
                        )}

                        {data?.redeeminfo?.param1value === "set3" && (
                          <>
                            &bull; Voucher 2,000 บาท
                            <br />
                            &bull; Vespa Voyager Pack
                            <br />
                            (กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็ดหน้า)
                            <br />
                            &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
                            <br />
                            &bull; Vespa Welcome Kit
                            <br />
                            &bull; Servizio Package
                            <br />
                            &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 HPE
                            Racing Sixties
                          </>
                        )}

                        {data?.redeeminfo?.param1value === "set4" && (
                          <>
                            &bull; Voucher 2,000 บาท
                            <br />
                            &bull; Vespa Voyager Pack
                            <br />
                            (กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็ดหน้า)
                            <br />
                            &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
                            <br />
                            &bull; Vespa Welcome Kit
                            <br />
                            &bull; Servizio Package
                            <br />
                            &bull; ชุดแต่ง Accessories รุ่น GTS 300 HPE 75th
                            Anniversary Special Edition
                          </>
                        )}

                        {data?.redeeminfo?.param1value === "set5" && (
                          <>
                            &bull; Voucher 2,000 บาท
                            <br />
                            &bull; Vespa Voyager Pack
                            <br />
                            (กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็ดหน้า)
                            <br />
                            &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
                            <br />
                            &bull; Vespa Welcome Kit
                            <br />
                            &bull; Servizio Package
                            <br />
                            &bull; ชุดแต่ง Accessories รุ่น Sei Giorni II
                            Edition 300 HPE
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {data?.itemid === "65BE367B-38F2-42E2-A208-501C9AF99FAE" && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>
                        ของแถมเพิ่มเติม
                      </div>
                      <div className={classes.redeemDetail}>
                        &bull; Voucher 15,000 บาท
                        <br />
                        &bull; Vespa Voyager Pack
                        <br />
                        (กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็ดหน้า)
                        <br />
                        &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
                        <br />
                        &bull; Vespa Welcome Kit
                        <br />
                        &bull; ประกันภัยชั้น 1 (1 ปี)
                      </div>
                    </div>
                  )}

                  {data?.itemid === "8B559848-6601-447F-B6D1-D411B1C5C227" && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>
                        ของแถมเพิ่มเติม
                      </div>
                      <div className={classes.redeemDetail}>
                        &bull; Voucher 15,000 บาท
                        <br />
                        &bull; Vespa Voyager Pack
                        <br />
                        (กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็ดหน้า)
                        <br />
                        &bull; New Normal Pack
                        <br />
                        (หน้ากากผ้าและแอลกอฮอล์สเปรย์)
                        <br />
                        &bull; Vespa Welcome Kit
                        <br />
                        &bull; ประกันภัยชั้น 1 (1 ปี)
                      </div>
                    </div>
                  )}

                  {(data?.itemid === "4FEF0A9B-8E9E-4E1D-8189-5CDF830FAFDC" ||
                    data?.itemid ===
                      "98FBEF29-0218-4131-A4A9-CB4013DD8396") && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>
                        ของแถมเพิ่มเติม
                      </div>
                      <div className={classes.redeemDetail}>
                        ผ้ายางรองพื้นตามรุ่น,
                      </div>
                      <div className={classes.redeemDetail}>
                        Rainy Day Survival Kit
                      </div>
                      <div className={classes.redeemDetail}>
                        (เสื้อกันฝน และกระเป๋ากันน้ำ),
                      </div>
                      <div className={classes.redeemDetail}>
                        หมวกและพวงกุญแจ,
                      </div>
                      <div className={classes.redeemDetail}>
                        New Normal Pack
                      </div>
                      <div className={classes.redeemDetail}>
                        (หน้ากากผ้าและแอลกอฮอล์สเปรย์),
                      </div>
                      <div className={classes.redeemDetail}>
                        Vespa Welcome Kit,
                      </div>
                      <div className={classes.redeemDetail}>
                        ประกัน COVID-19
                      </div>
                    </div>
                  )}

                  {data?.redeeminfo?.redeemenginenumber && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>เลขเครื่องยนต์</div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.redeemenginenumber}
                      </div>
                    </div>
                  )}

                  {data?.redeeminfo?.redeemchassisnumber && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>เลขถังรถ</div>
                      <div className={classes.redeemDetail}>
                        {data?.redeeminfo?.redeemchassisnumber}
                      </div>
                    </div>
                  )}

                  {data?.redeeminfo?.redeempurchase && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>วันที่ออกรถ</div>
                      <div className={classes.redeemDetail}>{purchaseDate}</div>
                    </div>
                  )}

                  {data?.redeeminfo?.redeemcouponserviceuse && (
                    <div className={classes.redeemBox}>
                      <div className={classes.redeemHeader}>
                        วันที่ใช้บริการ
                      </div>
                      <div className={classes.redeemDetail}>{serviceDate}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer imageIndex={2} />
      </div>
    </>
  );
};

export default MyUsedCouponDetail;

const RewardFillAddress48 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2021 && month === 12 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Voucher 2,000 บาท
              <br />
              &bull; กระเป๋าคาดเอว Alpinestars รุ่น Kanga-2
              <br />
              &bull; Vespa Essential Kit
              <br />
              &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Sport 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set2" && (
            <>
              &bull; Voucher 2,000 บาท
              <br />
              &bull; กระเป๋าคาดเอว Alpinestars รุ่น Kanga-2
              <br />
              &bull; Vespa Essential Kit
              <br />
              &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Tech 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set3" && (
            <>
              &bull; Voucher 2,000 บาท
              <br />
              &bull; กระเป๋าคาดเอว Alpinestars รุ่น Kanga-2
              <br />
              &bull; Vespa Essential Kit
              <br />
              &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 HPE Racing Sixties
            </>
          )}

          {data?.redeeminfo?.param1value === "set4" && (
            <>
              &bull; Voucher 2,000 บาท
              <br />
              &bull; กระเป๋าคาดเอว Alpinestars รุ่น Kanga-2
              <br />
              &bull; Vespa Essential Kit
              <br />
              &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS 300 HPE 75th Anniversary
              Special Edition
            </>
          )}

          {data?.redeeminfo?.param1value === "set5" && (
            <>
              &bull; Voucher 2,000 บาท
              <br />
              &bull; กระเป๋าคาดเอว Alpinestars รุ่น Kanga-2
              <br />
              &bull; Vespa Essential Kit
              <br />
              &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น Sei Giorni II Edition 300 HPE
            </>
          )}
        </div>
      </div>
    );
  }

  if (year === 2022 && month === 1 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Voucher 2,000 บาท
              <br />
              &bull; Vespa Essential Kit
              <br />
              &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Sport 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set2" && (
            <>
              &bull; Voucher 2,000 บาท
              <br />
              &bull; Vespa Essential Kit
              <br />
              &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Tech 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set3" && (
            <>
              &bull; Voucher 2,000 บาท
              <br />
              &bull; Vespa Essential Kit
              <br />
              &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 HPE Racing Sixties
            </>
          )}

          {data?.redeeminfo?.param1value === "set4" && (
            <>
              &bull; Voucher 2,000 บาท
              <br />
              &bull; Vespa Essential Kit
              <br />
              &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS 300 HPE 75th Anniversary
              Special Edition
            </>
          )}

          {data?.redeeminfo?.param1value === "set5" && (
            <>
              &bull; Voucher 2,000 บาท
              <br />
              &bull; Vespa Essential Kit
              <br />
              &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น Sei Giorni II Edition 300 HPE
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardCouponWithCond5B = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2021 && month === 12 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.redeembrandmodel ===
            "GTS SUPER 300 CC (MY 18)" && (
            <>
              &bull; Voucher 15,000 บาท
              <br />
              &bull; Vespa Essential Kit
              <br />
              &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; ประกันภัยชั้น 1 (1 ปี)
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 cc (MY18)
              <br />
              &bull; Top Box สีดำ พร้อมตะแกรงยึด
            </>
          )}

          {data?.redeeminfo?.redeembrandmodel ===
            "GTS SUPER SPORT 300 CC (MY 19)" && (
            <>
              &bull; Voucher 15,000 บาท
              <br />
              &bull; Vespa Essential Kit
              <br />
              &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; ประกันภัยชั้น 1 (1 ปี)
            </>
          )}
        </div>
      </div>
    );
  }

  if (year === 2022 && month === 1 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.redeembrandmodel ===
            "GTS SUPER 300 CC (MY 18)" && (
            <>
              &bull; Voucher 15,000 บาท
              <br />
              &bull; Vespa Essential Kit
              <br />
              &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; ประกันภัยชั้น 1 (1 ปี)
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 cc (MY18)
              <br />
            </>
          )}

          {data?.redeeminfo?.redeembrandmodel ===
            "GTS SUPER SPORT 300 CC (MY 19)" && (
            <>
              &bull; Voucher 15,000 บาท
              <br />
              &bull; Vespa Essential Kit
              <br />
              &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; ประกันภัยชั้น 1 (1 ปี)
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress50 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeemcouponserviceuse;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (
    (year === 2021 && month === 12 && date >= 1 && date <= 31) ||
    (year === 2022 && month === 1 && date >= 1 && date <= 31)
  ) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>มูลค่าคูปอง</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param2value}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress90 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 2 && date >= 1 && date <= 28) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}

          {data?.redeeminfo?.param1value === "set2" && (
            <>
              &bull; Voucher 1,500 บาท (ยกเว้น Pink Rosa)
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}

          {data?.redeeminfo?.param1value === "set3" && (
            <>
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress91 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 2 && date >= 1 && date <= 28) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Sport 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set2" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Tech 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set3" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 HPE Racing Sixties
            </>
          )}

          {data?.redeeminfo?.param1value === "set4" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS 300 HPE 75th Anniversary
              Special Edition
            </>
          )}

          {data?.redeeminfo?.param1value === "set5" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น Sei Giorni II Edition 300 HPE
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress96 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 3 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}

          {data?.redeeminfo?.param1value === "set2" && (
            <>
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}
          {data?.redeeminfo?.param1value === "set3" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Sport 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set4" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Tech 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set5" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 HPE Racing Sixties
            </>
          )}

          {data?.redeeminfo?.param1value === "set6" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS 300 HPE 75th Anniversary
              Special Edition
            </>
          )}

          {data?.redeeminfo?.param1value === "set7" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น Sei Giorni II Edition 300 HPE
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress100 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 3 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; ผ้ายางรองพื้น
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress101 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 4 && date >= 1 && date <= 30) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Voucher 2,500 บาท
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}

          {data?.redeeminfo?.param1value === "set2" && (
            <>
              &bull; Voucher 2,500 บาท (ยกเว้น Pink Rosa)
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress102 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 4 && date >= 1 && date <= 30) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}

          {data?.redeeminfo?.param1value === "set2" && (
            <>
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}
          {data?.redeeminfo?.param1value === "set3" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Sport 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set4" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Tech 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set5" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 HPE Racing Sixties
            </>
          )}

          {data?.redeeminfo?.param1value === "set6" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS 300 HPE 75th Anniversary
              Special Edition
            </>
          )}

          {data?.redeeminfo?.param1value === "set7" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น Sei Giorni II Edition 300 HPE
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress155 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 11 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.redeembrandmodel ===
            "GTS SUPER 300 CC (MY 18)" && (
            <>
              &bull; Voucher 15,000 บาท
              <br />
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; ประกันภัยชั้น 1 (1 ปี)
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 cc (MY18)
              <br />
            </>
          )}

          {data?.redeeminfo?.redeembrandmodel ===
            "GTS SUPER SPORT 300 CC (MY 19)" && (
            <>
              &bull; Voucher 15,000 บาท
              <br />
              &bull; Bumshaker Mug & Capt
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; ประกันภัยชั้น 1 (1 ปี)
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress104 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 4 && date >= 1 && date <= 30) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; ผ้ายางรองพื้น
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardCouponWithCond5D = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 2 && date >= 1 && date <= 28) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.redeembrandmodel ===
            "GTS SUPER 300 CC (MY 18)" && (
            <>
              &bull; Voucher 15,000 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; ประกันภัยชั้น 1 (1 ปี)
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 cc (MY18)
              <br />
              &bull; Top Box สีดำ พร้อมตะแกรงยึด
            </>
          )}

          {data?.redeeminfo?.redeembrandmodel ===
            "GTS SUPER SPORT 300 CC (MY 19)" && (
            <>
              &bull; Voucher 15,000 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; ประกันภัยชั้น 1 (1 ปี)
            </>
          )}
        </div>
      </div>
    );
  }

  if (year === 2022 && month === 3 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.redeembrandmodel ===
            "GTS SUPER 300 CC (MY 18)" && (
            <>
              &bull; Voucher 15,000 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; ประกันภัยชั้น 1 (1 ปี)
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 cc (MY18)
            </>
          )}

          {data?.redeeminfo?.redeembrandmodel ===
            "GTS SUPER SPORT 300 CC (MY 19)" && (
            <>
              &bull; Voucher 15,000 บาท
              <br />
              &bull; Vespa Pack & Go
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; ประกันภัยชั้น 1 (1 ปี)
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress93 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeemcouponserviceuse;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (
    year === 2022 &&
    ((month === 2 && date >= 16 && date <= 28) ||
      (month === 3 && date >= 1 && date <= 31) ||
      (month === 4 && date >= 1 && date <= 30) ||
      (month === 5 && date >= 1 && date <= 31))
  ) {
    return (
      <>
        <div className={classes.redeemBox}>
          <div className={classes.redeemHeader}>รายการอะไหล่</div>
          <div className={classes.redeemDetail}>
            <>
              &bull; สายพาน
              <br />
              &bull; ชุดเม็ดตุ้มแรงเหวี่ยง
              <br />
              &bull; ตัวล็อคเม็ดตุ้มแรงเหวี่ยง
              <br />
              &bull; หัวเทียน (ฟรี)
            </>
          </div>
        </div>
      </>
    );
  }

  return <div></div>;
};

const RewardFillAddress160 = ({ data }) => {
  const classes = muiStyles();
  return (
    <>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>รายการอะไหล่</div>
        <div className={classes.redeemDetail}>
          <>&bull; ส่วนลดน้ำมันเครื่อง 50%</>
        </div>
      </div>
    </>
  );
};

const RewardFillAddress247 = ({ data }) => {
  const classes = muiStyles();
  return (
    <>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>รายการอะไหล่</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param2value}
        </div>
      </div>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>คูปองที่ได้รับ</div>
        <div className={classes.redeemDetail}>
          <>
            &bull; {data?.redeeminfo?.param1value}
            <br />
          </>
        </div>
      </div>
    </>
  );
};
const RewardFillAddress233 = ({ data }) => {
  const classes = muiStyles();
  return (
    <>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>รายการอะไหล่</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value}
        </div>
      </div>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>คูปองที่ได้รับ</div>
        <div className={classes.redeemDetail}>
          {["GTS 300 CC", "SEI GIORNI 300 CC"].includes(
            data.redeeminfo.redeembrandmodel,
          ) ? (
            <>
              &bull; ไส้กรองอากาศ <br />
              &bull; ตุ้มแรงเหวี่ยง
            </>
          ) : (
            <>
              &bull; ไส้กรองอากาศ <br />
              &bull; หัวเทียน <br />
              &bull; ตุ้มแรงเหวี่ยง
            </>
          )}
        </div>
      </div>
    </>
  );
};

const RewardFillAddress161 = ({ data }) => {
  const classes = muiStyles();
  return (
    <>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>รายการอะไหล่</div>
        <div className={classes.redeemDetail}>
          <>&bull; {data?.redeeminfo?.param2value}</>
        </div>
      </div>
    </>
  );
};

const RewardFillAddress253 = ({ data }) => {
  const classes = muiStyles();
  return (
    <>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>สินค้าที่ซื้อ</div>

        <div className={classes.redeemDetail}>
          {data?.redeeminfo.param1value}
        </div>
      </div>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>คูปองที่ได้รับ</div>

        <div className={classes.redeemDetail}>&bull; ส่วนลด 10%</div>
      </div>
    </>
  );
};

const RewardFillAddress240 = ({ data }) => {
  const classes = muiStyles();
  return (
    <>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>สินค้าที่ซื้อ</div>

        <div className={classes.redeemDetail}>
          {data?.redeeminfo.param2value}
        </div>
      </div>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>คูปองที่ได้รับ</div>

        <div className={classes.redeemDetail}>
          {data?.redeeminfo.param1value}
        </div>
      </div>
    </>
  );
};

const RewardFillAddress298 = ({ data }) => {
  const classes = muiStyles();
  return (
    <>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>สินค้าที่ซื้อ</div>

        <div className={classes.redeemDetail}>
          {data?.redeeminfo.param2value}
        </div>
      </div>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>คูปองที่ได้รับ</div>

        <div className={classes.redeemDetail}>
          {data?.redeeminfo.param1value}
        </div>
      </div>
    </>
  );
};

const RewardFillAddress291 = ({ data }) => {
  const classes = muiStyles();
  return (
    <>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>แพ็กเกจที่ซื้อ</div>

        <div className={classes.redeemDetail}>
          {data?.redeeminfo.param2value}
        </div>
      </div>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>คูปองที่ได้รับ</div>

        <div className={classes.redeemDetail}>
          {data?.redeeminfo.param1value}
        </div>
      </div>
    </>
  );
};

const RewardFillAddress297 = ({ data }) => {
  const classes = muiStyles();
  return (
    <>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>รายการที่เปลี่ยน</div>

        <div className={classes.redeemDetail}>
          {data?.redeeminfo.param2value}
        </div>
      </div>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>คูปองที่ได้รับ</div>

        <div className={classes.redeemDetail}>
          {data?.redeeminfo.param1value}
        </div>
      </div>
    </>
  );
};

const RewardFillAddress154 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 11 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Miniature Vespa
              <br />
              &bull; Vespa Welcome Kit
              <br />
              (คูปองนี้ไม่สามารถใช้ได้กับรุ่น S 125 cc Touring)
            </>
          )}

          {data?.redeeminfo?.param1value === "set2" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Miniature Vespa
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress148 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 9 && date >= 1 && date <= 30) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Voucher 2,000 บาท
              <br />
              &bull; ผ้ายางรองพื้น
              <br />
              &bull; Bumshaker Buddy Bum Bag
              <br />
              &bull; Vespa Welcome Kit
              <br />
              (คูปองนี้ไม่สามารถใช้ร่วมกับรุ่น LX 125 cc Touring)
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress111 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 5 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}

          {data?.redeeminfo?.param1value === "set2" && (
            <>
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}
          {data?.redeeminfo?.param1value === "set3" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Sport 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set4" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Tech 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set5" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 HPE Racing Sixties
            </>
          )}

          {data?.redeeminfo?.param1value === "set6" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS 300 HPE 75th Anniversary
              Special Edition
            </>
          )}

          {data?.redeeminfo?.param1value === "set7" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Vespa Summer Ride
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น Sei Giorni II Edition 300 HPE
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress156 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 10 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}

          {data?.redeeminfo?.param1value === "set2" && (
            <>
              &bull; Vespa Welcome Kit
              <br />
            </>
          )}

          {data?.redeeminfo?.param1value === "set3" && (
            <>
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Sport 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set4" && (
            <>
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Tech 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set5" && (
            <>
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 HPE Racing Sixties
            </>
          )}

          {data?.redeeminfo?.param1value === "set6" && (
            <>
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS 300 HPE 75th Anniversary
              Special Edition
            </>
          )}

          {data?.redeeminfo?.param1value === "set7" && (
            <>
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น Sei Giorni II Edition 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set8" && (
            <>
              &bull; Voucher 500 บาท
              <br />
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress157 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 10 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Bumshaker Tote to go
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}
          {data?.redeeminfo?.param1value === "set2" && (
            <>&bull; Vespa Welcome Kit</>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress158 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 11 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Bumshaker Candy Corduroy
              <br />
              &bull; Vespa Welcome Kit
              <br />
              (คูปองนี้ไม่สามารถใช้ได้กับรุ่น LX 125 cc Touring)
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress159 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 11 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Bumshaker Touring Bag
              <br />
              &bull; Vespa Welcome Kit
              <br />
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress146 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeemcouponserviceuse;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (
    year === 2022 &&
    ((month === 2 && date >= 16 && date <= 28) ||
      (month === 3 && date >= 1 && date <= 31) ||
      (month === 4 && date >= 1 && date <= 30) ||
      (month === 5 && date >= 1 && date <= 31) ||
      (month === 6 && date >= 1 && date <= 30) ||
      (month === 7 && date >= 1 && date <= 31) ||
      (month === 8 && date >= 1 && date <= 31) ||
      (month === 9 && date >= 1 && date <= 30))
  ) {
    return (
      <>
        <div className={classes.redeemBox}>
          <div className={classes.redeemHeader}>รายการอะไหล่</div>
          <div className={classes.redeemDetail}>
            {data?.redeeminfo?.param1value === "set1" && (
              <>&bull; ส่วนลดสายพาน 50%</>
            )}
            {data?.redeeminfo?.param1value === "set2" && (
              <>&bull; ส่วนลดสายพาน 50%</>
            )}
          </div>
        </div>
      </>
    );
  }

  return <div></div>;
};

const RewardFillAddress131 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeemcouponserviceuse;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (
    year === 2022 &&
    ((month === 2 && date >= 16 && date <= 28) ||
      (month === 3 && date >= 1 && date <= 31) ||
      (month === 4 && date >= 1 && date <= 30) ||
      (month === 5 && date >= 1 && date <= 31) ||
      (month === 6 && date >= 1 && date <= 30) ||
      (month === 7 && date >= 1 && date <= 31))
  ) {
    return (
      <>
        <div className={classes.redeemBox}>
          <div className={classes.redeemHeader}>รายการอะไหล่</div>
          <div className={classes.redeemDetail}>
            {data?.redeeminfo?.param1value === "set1" && (
              <>
                &bull; กันล้มหลังสีโครเมี่ยม
                <br />
                &bull; ตะแกรงหลังพับได้สีโครเมี่ยม
                <br />
                &bull; ฟรี Bumshaker Kid Helmet 1 ใบ (คละสี)
                <br />
              </>
            )}
            {data?.redeeminfo?.param1value === "set2" && (
              <>
                &bull; กันล้มหลังสีดำด้าน
                <br />
                &bull; ตะแกรงหลังพับได้สีดำด้าน
                <br />
                &bull; ฟรี Bumshaker Kid Helmet 1 ใบ (คละสี)
                <br />
              </>
            )}
            {data?.redeeminfo?.param1value === "set3" && (
              <>
                &bull; กระจกบังลมสั้นสีใส
                <br />
                &bull; กันล้มหน้าสีดำด้าน
                <br />
                &bull; ฟรี Bumshaker Kid Helmet 1 ใบ (คละสี)
                <br />
              </>
            )}
            {data?.redeeminfo?.param1value === "set4" && (
              <>
                &bull; กระจกบังลมสั้นสีสโม๊ค
                <br />
                &bull; กันล้มหน้าสีโครเมี่ยม
                <br />
                &bull; ฟรี Bumshaker Kid Helmet 1 ใบ (คละสี)
                <br />
              </>
            )}
            {data?.redeeminfo?.param1value === "set5" && (
              <>
                &bull; กระจกบังลมสั้นสีสโม๊ค
                <br />
                &bull; ตะแกรงหลังพับได้สีโครเมี่ยม
                <br />
                &bull; ฟรี Bumshaker Kid Helmet 1 ใบ (คละสี)
                <br />
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  return <div></div>;
};

const RewardFillAddress139 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeemcouponserviceuse;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (
    year === 2022 &&
    ((month === 2 && date >= 16 && date <= 28) ||
      (month === 3 && date >= 1 && date <= 31) ||
      (month === 4 && date >= 1 && date <= 30) ||
      (month === 5 && date >= 1 && date <= 31) ||
      (month === 6 && date >= 1 && date <= 30) ||
      (month === 7 && date >= 1 && date <= 31) ||
      (month === 8 && date >= 1 && date <= 31) ||
      (month === 9 && date >= 1 && date <= 30))
  ) {
    return (
      <>
        <div className={classes.redeemBox}>
          <div className={classes.redeemHeader}>รายการอะไหล่</div>
          <div className={classes.redeemDetail}>
            {data?.redeeminfo?.param1value === "set1" && (
              <>
                &bull; รับสิทธิ์ซื้อ กระจกบังลมสั้น มูลค่า 1,500 บาท จำนวน 1
                ชิ้น (ทุกรุ่น)
              </>
            )}
            {data?.redeeminfo?.param1value === "set2" && (
              <>
                &bull; รับสิทธิ์ซื้อ ตะแกรงหน้าหรือตะแกรงหลัง มูลค่า 500 บาท
                จำนวน 1 ชิ้น (ทุกรุ่น)
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  return <div></div>;
};

const RewardFillAddress147 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeemcouponserviceuse;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (
    year === 2022 &&
    ((month === 2 && date >= 16 && date <= 28) ||
      (month === 3 && date >= 1 && date <= 31) ||
      (month === 4 && date >= 1 && date <= 30) ||
      (month === 5 && date >= 1 && date <= 31) ||
      (month === 6 && date >= 1 && date <= 30) ||
      (month === 7 && date >= 1 && date <= 31) ||
      (month === 8 && date >= 1 && date <= 31) ||
      (month === 9 && date >= 1 && date <= 30))
  ) {
    return (
      <>
        <div className={classes.redeemBox}>
          <div className={classes.redeemHeader}>รายการอะไหล่</div>
          <div className={classes.redeemDetail}>
            {data?.redeeminfo?.param1value === "set1" && (
              <>
                &bull; รับสิทธิ์ซื้อ กระจกบังลมสั้น มูลค่า 1,500 บาท จำนวน 1
                ชิ้น (ทุกรุ่น)
              </>
            )}
            {data?.redeeminfo?.param1value === "set2" && (
              <>
                &bull; รับสิทธิ์ซื้อ ตะแกรงหน้าหรือตะแกรงหลัง มูลค่า 500 บาท
                จำนวน 1 ชิ้น (ทุกรุ่น)
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  return <div></div>;
};

const RewardFillAddress167 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 11 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Voucher 1,000 บาท
              <br />
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
            </>
          )}
          {data?.redeeminfo?.param1value === "set2" && (
            <>
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}

          {data?.redeeminfo?.param1value === "set3" && (
            <>
              &bull; Vespa Welcome Kit
              <br />
            </>
          )}

          {data?.redeeminfo?.param1value === "set4" && (
            <>
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Sport 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set5" && (
            <>
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super Tech 300 HPE
            </>
          )}

          {data?.redeeminfo?.param1value === "set6" && (
            <>
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 HPE Racing Sixties
            </>
          )}

          {data?.redeeminfo?.param1value === "set7" && (
            <>
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น GTS 300 HPE 75th Anniversary
              Special Edition
            </>
          )}

          {data?.redeeminfo?.param1value === "set8" && (
            <>
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
              &bull; Servizio Package
              <br />
              &bull; ชุดแต่ง Accessories รุ่น Sei Giorni II Edition 300 HPE
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress168 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 11 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Voucher 1,000 บาท
              <br />
              &bull; Bumshaker Tote to go
              <br />
              &bull; Vespa Welcome Kit
              <br />
            </>
          )}
          {data?.redeeminfo?.param1value === "set2" && (
            <>
              &bull; Bumshaker Tote to go
              <br />
              &bull; Vespa Welcome Kit
            </>
          )}

          {data?.redeeminfo?.param1value === "set3" && (
            <>
              &bull; Vespa Welcome Kit
              <br />
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress175 = ({ data }) => {
  const classes = muiStyles();
  var redeemdate = data?.redeeminfo?.redeempurchase;
  var date = parseInt(redeemdate.split("-")[2]);
  var month = parseInt(redeemdate.split("-")[1]);
  var year = parseInt(redeemdate.split("-")[0]);

  if (year === 2022 && month === 11 && date >= 1 && date <= 31) {
    return (
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value === "set1" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
            </>
          )}
          {data?.redeeminfo?.param1value === "set2" && (
            <>
              &bull; Bumshaker Mug & Cap
              <br />
              &bull; Vespa Welcome Kit
              <br />
            </>
          )}
          {data?.redeeminfo?.param1value === "set3" && (
            <>
              &bull; Vespa Welcome Kit
              <br />
            </>
          )}
          {data?.redeeminfo?.param1value === "set4" && (
            <>
              &bull; Voucher 1,500 บาท
              <br />
              &bull; Bumshaker Tote to go
              <br />
              &bull; Vespa Welcome Kit
              <br />
            </>
          )}
          {data?.redeeminfo?.param1value === "set5" && (
            <>
              &bull; Bumshaker Tote to go
              <br />
              &bull; Vespa Welcome Kit
              <br />
            </>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
};

const RewardFillAddress229 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher 10,000 บาท
            <br />
            &bull; ผ้ายางรองพื้น
            <br />
            &bull; ชุดแต่ง Accessories
            <br />
            &bull; Vespa Licensing
            <br />
            &bull; Welcome Kit
            <br />
          </>
        )}
        {data?.redeeminfo?.param1value === "set2" && (
          <>
            &bull; Voucher 5,000 บาท
            <br />
            &bull; ผ้ายางรองพื้น
            <br />
            &bull; Vespa Licensing
            <br />
            &bull; Welcome Kit
            <br />
          </>
        )}
        {data?.redeeminfo?.param1value === "set3" && (
          <>
            &bull; ผ้ายางรองพื้น
            <br />
            &bull; ชุดแต่ง Accessories
            <br />
            &bull; Vespa Licensing
            <br />
            &bull; Welcome Kit
            <br />
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress266 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher 4,000 บาท
            <br />
            &bull; Vespa Happy Rain
            <br />
            &bull; Welcome Kit
            <br />
          </>
        )}
        {data?.redeeminfo?.param1value === "set2" && (
          <>
            &bull; Voucher 4,000 บาท
            <br />
            &bull; Touring Bag
            <br />
            &bull; Vespa Happy Rain
            <br />
            &bull; Welcome Kit
            <br />
          </>
        )}
        {data?.redeeminfo?.param1value === "set3" && (
          <>
            &bull; Voucher 4,000 บาท <br />
            &bull; Sand & Sea Premium Set <br />
            &bull; Vespa Happy Rain <br />
            &bull; Welcome Kit"
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress179 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher 2,000 บาท
            <br />
            &bull; Bumshaker Mug & Cap
            <br />
            &bull; Vespa Welcome Kit
            <br />
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress181 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher 2,000 บาท
            <br />
            &bull; Bumshaker Mug & Cap
            <br />
            &bull; ชุดแต่ง Accessories
            <br />
            &bull; Vespa Welcome Kit
            <br />
          </>
        )}
        {data?.redeeminfo?.param1value === "set2" && (
          <>
            &bull; Voucher 2,000 บาท
            <br />
            &bull; Bumshaker Mug & Cap
            <br />
            &bull; Vespa Welcome Kit
            <br />
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress216 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.redeembrandmodel === "GTS SUPER 300 CC (MY 18)" && (
          <>
            &bull; Special Voucher 1,000 บาท
            <br />
            &bull; Voucher 15,000 บาท
            <br />
            &bull; Vespa Friend-Trip
            <br />
            &bull; Vespa Welcome Kit
            <br />
            &bull; ประกันภัยชั้น 1 (1 ปี)
            <br />
            &bull; ชุดแต่ง Accessories
          </>
        )}
        {data?.redeeminfo?.redeembrandmodel ===
          "GTS SUPER SPORT 300 CC (MY 19)" && (
          <>
            &bull; Special Voucher 1,000 บาท
            <br />
            &bull; Voucher 15,000 บาท
            <br />
            &bull; Vespa Friend-Trip
            <br />
            &bull; Vespa Welcome Kit
            <br />
            &bull; ประกันภัยชั้น 1 (1 ปี)
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress267 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher 4,000 บาท
            <br />
            &bull; Vespa Happy Rain
            <br />
            &bull; Welcome Kit
            <br />
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress268 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher 4,000 บาท
            <br />
            &bull; Vespa Happy Rain
            <br />
            &bull; Welcome Kit
            <br />
          </>
        )}
        {data?.redeeminfo?.param1value === "set2" && (
          <>
            &bull; Voucher 4,000 บาท
            <br />
            &bull; Bumshaker Hype Box และชุดแต่ง
            <br />
            &bull; Vespa Happy Rain
            <br />
            &bull; Welcome Kit
            <br />
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress223 = ({ data }) => {
  const classes = muiStyles();
  return (
    <>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>
          {data?.redeeminfo?.param1 === "usage"
            ? "มูลค่าสินค้าที่ซื้อ"
            : "ค่าใช้จ่าย"}
        </div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value}
          <br />
        </div>
      </div>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>คูปองที่ได้รับ</div>
        <div className={classes.redeemDetail}>
          &bull; {data?.redeeminfo?.param2value}
          <br />
        </div>
      </div>
    </>
  );
};

const RewardFillAddress234 = ({ data }) => {
  const classes = muiStyles();
  return (
    <>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>สินค้าที่ซื้อ</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param1value}
          <br />
        </div>
      </div>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>สี</div>
        <div className={classes.redeemDetail}>
          {data?.redeeminfo?.param2value}
          <br />
        </div>
      </div>
      <div className={classes.redeemBox}>
        <div className={classes.redeemHeader}>คูปองที่ได้รับ</div>
        <div className={classes.redeemDetail}>&bull; ส่วนลด 30%</div>
      </div>
    </>
  );
};

const RewardFillAddress292 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher ตามมูลค่าที่กำหนด
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set2" && (
          <>
            &bull; Voucher ตามมูลค่าที่กำหนด
            <br />
            &bull; Touring Bag
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set3" && (
          <>
            &bull; Voucher ตามมูลค่าที่กำหนด
            <br />
            &bull; Sand & Sea Premium Set
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set4" && (
          <>
            &bull; Pink Rosa Premium Set
            <br />
            &bull; Welcome Kit
          </>
        )}
      </div>
    </div>
  );
};
const RewardFillAddress293 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher ตามมูลค่าที่กำหนด
            <br />
            &bull; Welcome Kit
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress294 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher ตามมูลค่าที่กำหนด
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set2" && (
          <>
            &bull; Voucher ตามมูลค่าที่กำหนด
            <br />
            &bull; Bumshaker Hype Box และชุดแต่ง
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set3" && <>&bull; Welcome Kit</>}
      </div>
    </div>
  );
};

const RewardFillAddress295 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher ตามมูลค่าที่กำหนด
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set2" && (
          <>
            &bull; Voucher ตามมูลค่าที่กำหนด
            <br />
            &bull; Maintenance 1 ปี (3 ระยะ)
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set3" && (
          <>
            &bull; Maintenance 1 ปี (3 ระยะ)
            <br />
            &bull; Welcome Kit
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress269 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher 5,000 บาท
            <br />
            &bull; ผ้ายางรองพื้น กับกระจกบังลมสั้น
            <br />
            &bull; Vespa Happy Rain
            <br />
            &bull; Maintenance 1 ปี (3 ระยะ)
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set2" && (
          <>
            &bull; Voucher 10,000 บาท
            <br />
            &bull; ชุดแต่ง Accessories
            <br />
            &bull; Vespa Happy Rain
            <br />
            &bull; Servizio Package
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set3" && (
          <>
            &bull; Voucher 5,000 บาท
            <br />
            &bull; Vespa Happy Rain
            <br />
            &bull; Maintenance 1 ปี (3 ระยะ)
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set4" && (
          <>
            &bull; Voucher 10,000 บาท
            <br />
            &bull; Vespa Happy Rain
            <br />
            &bull; Maintenance 1 ปี (3 ระยะ)
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set5" && (
          <>
            &bull; ชุดแต่ง Orange Flyscreen / Racing Cover
            <br />
            &bull; Vespa Happy Rain
            <br />
            &bull; Maintenance 1 ปี (3 ระยะ)
            <br />
            &bull; Welcome Kit
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress220 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; ผ้ายางรองพื้น
            <br />
            &bull; Vespa Friend-Trip
            <br />
            &bull; ชุดแต่ง Accessories
            <br />
            &bull; Vespa Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set2" && (
          <>
            &bull; ผ้ายางรองพื้น
            <br />
            &bull; Vespa Friend-Trip
            <br />
            &bull; Vespa Welcome Kit
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress221 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.redeembrandmodel === "GTS SUPER 300 CC (MY 18)" && (
          <>
            &bull; Voucher 15,000 บาท <br />
            &bull; Vespa Friend-Trip <br />
            &bull; ผ้ายางรองพื้น
            <br />
            &bull; Vespa Welcome Kit <br />
            &bull; ประกันภัยชั้น 1 (1 ปี) <br />
            &bull; ชุดแต่ง Accessories
          </>
        )}
        {data?.redeeminfo?.redeembrandmodel ===
          "GTS SUPER SPORT 300 CC (MY 19)" && (
          <>
            &bull; Voucher 15,000 บาท <br />
            &bull; Vespa Friend-Trip <br />
            &bull; ผ้ายางรองพื้น
            <br />
            &bull; Vespa Welcome Kit <br />
            &bull; ประกันภัยชั้น 1 (1 ปี) <br />
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress249 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher 1,000 บาท
            <br />
            &bull; Duo Set
            <br />
            &bull; Maintenance 1 ปี (3 ระยะ)
            <br />
            &bull; Welcome Kit
            <br />
          </>
        )}
        {data?.redeeminfo?.param1value === "set2" && (
          <>
            &bull; Voucher 1,000 บาท
            <br />
            &bull; Touring Bag
            <br />
            &bull; Maintenance 1 ปี (3 ระยะ)
            <br />
            &bull; Welcome Kit
            <br />
          </>
        )}
        {data?.redeeminfo?.param1value === "set3" && (
          <>
            &bull; Voucher 1,000 บาท
            <br />
            &bull; Premium Set เฉพาะรุ่น
            <br />
            &bull; Maintenance 1 ปี (3 ระยะ)
            <br />
            &bull; Welcome Kit
            <br />
            &bull; Welcome Kit
            <br />
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress262 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher 4,000 บาท
            <br />
            &bull; Vespa Mug
            <br />
            &bull; Welcome Kit
            <br />
          </>
        )}
        {data?.redeeminfo?.param1value === "set2" && (
          <>
            &bull; Vespa Mug
            <br />
            &bull; Welcome Kit
            <br />
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress261 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher 3,000 บาท
            <br />
            &bull; Candy Corduroy
            <br />
            &bull; Maintenance 1 ปี (3 ระยะ)
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set2" && (
          <>
            &bull; Voucher 3,000 บาท
            <br />
            &bull; Candy Corduroy
            <br />
            &bull; Maintenance 1 ปี (3 ระยะ)
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set3" && (
          <>
            &bull; Voucher 3,000 บาท
            <br />
            &bull; Touring Bag
            <br />
            &bull; Maintenance 1 ปี (3 ระยะ)
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set4" && (
          <>
            &bull; Voucher 1,500 บาท
            <br />
            &bull; Sand & Sea Premium Set
            <br />
            &bull; Maintenance 1 ปี (3 ระยะ)
            <br />
            &bull; Welcome Kit
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress263 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Voucher 4,000 บาท
            <br />
            &bull; Mug & Cap
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set2" && (
          <>
            &bull; Mug & Cap
            <br />
            &bull; Welcome Kit
          </>
        )}
        {data?.redeeminfo?.param1value === "set3" && (
          <>
            &bull; Bumshaker Hype Box และชุดแต่ง
            <br />
            &bull; Welcome Kit
          </>
        )}
      </div>
    </div>
  );
};

const RewardFillAddress213 = ({ data }) => {
  const classes = muiStyles();
  return (
    <div className={classes.redeemBox}>
      <div className={classes.redeemHeader}>ของแถมเพิ่มเติม</div>
      <div className={classes.redeemDetail}>
        {data?.redeeminfo?.param1value === "set1" && (
          <>
            &bull; Special Voucher 1,000 บาท
            <br />
            &bull; Voucher 3,000 บาท
            <br />
            &bull; Vespa Friend-Trip
            <br />
            &bull; Vespa Welcome Kit
            <br />
          </>
        )}
        {data?.redeeminfo?.param1value === "set2" && (
          <>
            &bull; Special Voucher 1,000 บาท
            <br />
            &bull; Voucher 2,000 บาท
            <br />
            &bull; Vespa Friend-Trip
            <br />
            &bull; Vespa Welcome Kit
            <br />
          </>
        )}
        {data?.redeeminfo?.param1value === "set3" && (
          <>
            &bull; Special Voucher 1,000 บาท
            <br />
            &bull; Voucher 1,000 บาท
            <br />
            &bull; Vespa Friend-Trip
            <br />
            &bull; Vespa Welcome Kit
            <br />
          </>
        )}
      </div>
    </div>
  );
};
