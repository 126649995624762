import React, { useState, useEffect } from "react";
import { encode, humanExpireTime } from "./helpper";
import AppBar from "../components/AppBar";
import Footer from "../components/Footer";
import "../App.css";
import muiStyles from "./muiStyle";
import useLiff from "../hooks/useAuth";
import axios from "axios";
import { API, isDealer } from "../hooks/api";
import { getCoupons, useAPIQuery } from "../hooks/queries";
import { useHistory } from "react-router-dom";
import { getBranchID } from "../hooks/user";

const Privileges = () => {
  const classes = muiStyles();
  const history = useHistory();
  const { getProfile, getAccessToken } = useLiff();
  const token = getAccessToken;
  const profile = getProfile;

  const branchId = getBranchID();

  const onClickTrack = async (token) => {
    const queryParams = new URLSearchParams(window.location.search);
    const tracking = queryParams.get("track");

    if (tracking) {
      const param = {
        accesstoken: token,
        code: "MainAccountProfile",
      };
      const encodedData = encode(param);
      const response = await axios.post(API.TRACKER, encodedData);
    }
  };

  onClickTrack(token);

  const param = {
    accesstoken: token,
    lineuserid: profile.userId,
    type: "Reward",
  };
  const encodedData = encode(param);
  const coupons = useAPIQuery("coupons", () => getCoupons(encodedData));
  const privilegesList = coupons.data?.items ?? [];

  // const initMaster = async () => {
  //   let couponRandom;
  //   if (!isDealer) {
  //     const paramRandom = {
  //       accesstoken: token,
  //       lineuserid: profile.userId,
  //       type: "RewardRandom2022-2",
  //     };
  //     const encodedDataRandom = encode(paramRandom);
  //     couponRandom = await axios.post(
  //       API.LOAD_RANDOM_COUPON,
  //       encodedDataRandom,
  //     );
  //   }
  //
  //   const param = {
  //     accesstoken: token,
  //     lineuserid: profile.userId,
  //     type: "Reward",
  //   };
  //   const encodedData = encode(param);
  //
  //   const coupons = await axios.post(
  //     isDealer ? API.LOAD_COUPON_DEALER : API.LOAD_COUPON_LIST,
  //     encodedData,
  //   );
  //
  //   const _list = [
  //     ...(couponRandom?.data?.items || []),
  //     ...(coupons?.data?.items ?? []),
  //   ];
  //
  //   setPrivilegesList(_list);
  // };

  return (
    <div className={classes.container}>
      <AppBar />

      <div className={classes.content}>
        <div className={classes.wrapper}>
          <div className={classes.heading}>สิทธิพิเศษ</div>

          {!privilegesList.length ? (
            <div className={classes.coupon}>
              <div className={classes.empty}>
                <div className={classes.emptyMsg}>ไม่มีคูปอง</div>
              </div>
            </div>
          ) : (
            <div className={classes.coupon}>
              <div className={classes.coupons}>
                {privilegesList.map((m, i) => {
                  const expired =
                    m.isRunOut === "true" ||
                    m.isRedeemed === "true" ||
                    m.allowpickup === "false";
                  return (
                    <div className={classes.couponItem} key={i}>
                      <img
                        className="coupon__image"
                        src={m.assetsquarewithbutton}
                        alt="coupon"
                      />
                      <div className={classes.couponFooter}>
                        <div className={classes.couponContentFooter}>
                          {" "}
                          {m.expiretime === ""
                            ? "จนกว่าของพรีเมียมจะหมด"
                            : `หมดเขต ${humanExpireTime(m.validend)}`}
                        </div>
                        <div className={classes.couponExtraContentFooter}>
                          <div className={classes.button}>
                            <a
                              className={classes.btnFont}
                              onClick={() =>
                                history.push(
                                  m.type === "RewardRandom2022-2"
                                    ? `/privileges-detail-random/${m.type}?branchId=${branchId}`
                                    : `/privileges-detail/${m.itemid}?branchId=${branchId}`,
                                )
                              }
                            >
                              กดใช้คูปอง
                            </a>
                          </div>
                        </div>
                      </div>

                      {m.isRedeemed === "true" ? (
                        <div className={classes.couponRunout}>
                          คุณได้ใช้สิทธิ์แล้ว
                        </div>
                      ) : m.allowpickup === "false" ? (
                        <div className={classes.couponRunout}>
                          ไม่สามารถใช้สิทธิ์ได้
                        </div>
                      ) : m.isRunOut === "true" ? (
                        <div className={classes.couponRunout}>
                          {m.type === "RewardRandom2022-2"
                            ? "สิทธิ์การสุ่มรางวัลครบแล้ว"
                            : "ใช้สิทธิ์ครบแล้ว"}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer imageIndex={3} />
    </div>
  );
};

export default Privileges;
