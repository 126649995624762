import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Privileges from "./page/Privilleges";
import MyUsedCoupon from "./page/MyUsedCoupon";
import MyProfile from "./page/MyProfile";
import MyNewCoupon from "./page/MyNewCoupon";
import MyNewCouponDetail from "./page/MyNewCouponDetail";
import MyNewCouponDetailRandom from "./page/MyNewCouponDetailRandom";
import PrivilegesDetail from "./page/PrivillegesDetail";
import PrivilegesDetailRandom from "./page/PrivillegesDetailRandom";
import { LiffProvider } from "./contexts/LiffContext";
import MyUsedCouponDetail from "./page/MyUsedCouponDetail";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouteWithPermission } from "./components/RouteWithPermission";
import VespaCallBack from "./page/VespaCallBack";
import "./App.css";
import "./css/common.css";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "./hooks/queries";
import { storeBranchID } from "./hooks/user";

const routes = [
  {
    path: "/privileges",
    component: Privileges,
  },
  {
    path: "/my-used-coupon",
    component: MyUsedCoupon,
  },
  {
    path: "/my-new-coupon",
    component: MyNewCoupon,
  },
  {
    path: "/my-profile",
    component: MyProfile,
  },
  {
    path: "/privileges-detail/:id",
    component: PrivilegesDetail,
  },
  {
    path: "/privileges-detail-random/:id",
    component: PrivilegesDetailRandom,
  },
  {
    path: "/coupon-detail/:id",
    component: MyNewCouponDetail,
  },
  {
    path: "/coupon-detail-random/:id",
    component: MyNewCouponDetailRandom,
  },
  {
    path: "/my-used-coupon-detail/:id",
    component: MyUsedCouponDetail,
  },
  {
    path: "/vespaid_callback",
    component: VespaCallBack,
  },
  {
    path: "/",
    component: Privileges,
  },
];

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <LiffProvider>
          <Switch>
            {routes.map(({ path, component }) => (
              <RouteWithPermission
                path={path}
                component={component}
                key={path}
              />
            ))}
          </Switch>
        </LiffProvider>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
