import React, { createContext, useEffect, useState } from "react";
import liff from "@line/liff";
import { liffId } from "../hooks/api";
import Loading from "../components/Loading";
import { storeBranchID } from "../hooks/user";

const LiffContext = createContext();

export const LiffProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const userData = user;
  const queryParams = new URLSearchParams(window.location.search);
  const branchId = queryParams.get("branchId");
  branchId && storeBranchID(branchId);
  const fetchLiff = async () => {
    await liff.init({ liffId: liffId }).catch((err) => {
      throw err;
    });

    if (liff.isLoggedIn()) {
      const profile = await liff.getProfile();
      const me = {
        getProfile: profile,
        isLogIn: liff.isLoggedIn,
        getAccessToken: liff.getAccessToken(),
      };
      setUser(me);
    } else {
      liff.login();
    }
  };

  useEffect(() => {
    fetchLiff();
  }, user);

  if (!Boolean(userData)) return <Loading />;
  return (
    <LiffContext.Provider value={userData}>{children}</LiffContext.Provider>
  );
};

export default LiffContext;
