import React from "react";
import muiStyles from "../page/muiStyle";
import LoadingGif from "../images/loader__image.gif";

const Loading = () => {
  const classes = muiStyles();
  return (
    <div className={classes.overlay}>
      <div className={classes.loader}>
        <img className={classes.loaderImage} src={LoadingGif} alt="" />
      </div>
    </div>
  );
};

export default Loading;
