const branchKey = "branchId";
const requiredLoginWithVespaKey = "requiredLoginWithVespa";

export const storeBranchID = (branchID) => {
  localStorage.setItem(branchKey, branchID);
};

export const getBranchID = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const localStorageBranchId = localStorage.getItem(branchKey);
  return queryParams.get(branchKey) ?? localStorageBranchId;
};

export const setRequiredLoginWithVespaState = (init) =>
  localStorage.setItem(requiredLoginWithVespaKey, init);

export const getRequiredLoginWithVespaState = () =>
  JSON.parse(localStorage.getItem(requiredLoginWithVespaKey));

export const resetRequiredLoginWithVespaState = () =>
  localStorage.removeItem(requiredLoginWithVespaKey);
