import axios from "axios";
import { API, isDealer } from "./api";
import { QueryClient, useQuery } from "react-query";

export const useAPIQuery = (key, query, options) =>
  useQuery(key, query, { enabled: true, ...options });

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      staleTime: Infinity,
      refetchOnMount: true,
    },
  },
});

export const checkProfile = async (encodedData) => {
  const { data } = await axios.post(API.CHECK_PROFILE, encodedData);
  return data;
};

export const getModel = async (encodedData) => {
  const { data } = await axios.post(API.GET_MODEL, encodedData);
  return data;
};

export const myProfile = async (encodedData) => {
  const { data } = await axios.post(API.LOAD_PROFILE, encodedData);
  return data;
};

export const getCoupons = async (encodedData) => {
  const { data } = await axios.post(
    isDealer ? API.LOAD_COUPON_DEALER : API.LOAD_COUPON_LIST,
    encodedData,
  );
  return data;
};

export const getModels = async (encodedData) => {
  const { data } = await axios.post(API.GET_MODEL, encodedData);
  return data;
};

export const getMyCoupons = async (encodedData) => {
  const { data } = await axios.post(API.LOAD_MY_COUPON, encodedData);
  return data;
};

export const tracker = async (encodedData) => {
  const { data } = await axios.post(API.TRACKER, encodedData);
  return data;
};
