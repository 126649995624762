import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import MenuProfileImg from "../images/menu--profile.jpg";
import MenuCouponImg from "../images/menu--coupon.jpg";
import MenuPrivilegeImg from "../images/menu--privilege.jpg";
import clsx from "clsx";
import "../App.css";
import { getBranchID } from "../hooks/user";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: "300px",
    "@media (max-width: 768px)": {
      height: "230px",
    },
    "@media (max-width: 600px)": {
      height: "160px",
    },
    "@media (max-width: 500px)": {
      height: "110px",
    },
  },
  image1: {
    background: `url(${MenuProfileImg}) no-repeat 0 0/100%`,
  },
  image2: {
    background: `url(${MenuCouponImg}) no-repeat 0 0/100%`,
  },
  image3: {
    background: `url(${MenuPrivilegeImg}) no-repeat 0 0/100%`,
  },
}));

const Footer = ({ imageIndex }) => {
  const classes = useStyles();
  const history = useHistory();
  const branchId = getBranchID();

  return (
    <div
      className={clsx(
        classes.footer,
        imageIndex === 1
          ? classes.image1
          : imageIndex === 2
            ? classes.image2
            : classes.image3,
      )}
    >
      <div className="menu" style={{ height: 240 }}>
        <div className="menu__list">
          <div className="menu__item">
            <a
              onClick={() => history.push(`/my-profile?branchId=${branchId}`)}
              className="menu__anchor"
            />
          </div>
          <div className="menu__item">
            <a
              onClick={() =>
                history.push(`/my-new-coupon?branchId=${branchId}`)
              }
              className="menu__anchor"
            />
          </div>
          <div className="menu__item">
            <a
              onClick={() => history.push(`/privileges?branchId=${branchId}`)}
              className="menu__anchor"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
