import React, { useEffect, useState } from "react";
import { encode, humanExpireTime } from "./helpper";
import AppBar from "../components/AppBar";
import Footer from "../components/Footer";
import AppBarNone from "../components/AppBarNone";
import clsx from "clsx";
import muiStyles from "./muiStyle";
import "../App.css";
import useLiff from "../hooks/useAuth";
import axios from "axios";
import { API, isDealer } from "../hooks/api";
import { getCoupons, getMyCoupons, useAPIQuery } from "../hooks/queries";
import { useHistory } from "react-router-dom";
import { getBranchID } from "../hooks/user";

const MyUsedCoupon = () => {
  const classes = muiStyles();
  const history = useHistory();
  const { getProfile, getAccessToken } = useLiff();
  const token = getAccessToken;
  const profile = getProfile;

  const branchId = getBranchID();
  const param = {
    accesstoken: token,
    lineuserid: profile.userId,
    ...(isDealer && { dealerid: branchId }),
  };
  const encodedData = encode(param);
  const myCoupons = useAPIQuery("myCoupons", () => getMyCoupons(encodedData));
  const couponList =
    myCoupons?.data?.items.filter(
      (item) => item.isGroupUsed === "true" || item.isExpired === "true",
    ) ?? [];

  return (
    <>
      <div className={classes.container}>
        <AppBar />
        <div className={classes.content}>
          <div className={classes.wrapper}>
            <div className={classes.heading}>
              <div className={classes.headingTab}>
                <a
                  onClick={() =>
                    history.push(`/my-new-coupon?branchId=${branchId}`)
                  }
                  className={classes.headingAnchor}
                >
                  คูปองที่ใช้ได้
                </a>
                <a
                  onClick={() =>
                    history.push(`/my-used-coupon?branchId=${branchId}`)
                  }
                  className={clsx(
                    classes.headingAnchor,
                    classes.headingAnchorActive,
                  )}
                >
                  คูปองที่ใช้แล้ว/หมดอายุ
                </a>
              </div>
            </div>

            {couponList === undefined || couponList.length === 0 ? (
              <div className={classes.coupon}>
                <div className={classes.empty}>
                  <div className={classes.emptyMsg}>ไม่มีคูปอง</div>
                </div>
              </div>
            ) : (
              <div className={classes.coupon}>
                <div className={classes.coupons}>
                  {couponList.map((m, i) => {
                    const noRewarded = m.titleen === "No Rewarded";
                    const notShow =
                      m.activatedate === "" && m.isExpired === "false";
                    const expired =
                      m.activatedate !== "" ||
                      m.isExpired === "true" ||
                      m.isRunOut === "true" ||
                      m.isGroupUsed === "true";
                    if (noRewarded) {
                      const isRunOut = !m.isRunOut;
                      return (
                        <div className={classes.couponItem} key={i}>
                          <img
                            className="coupon__image coupon__anchor--expired"
                            src={m.assetrectangle}
                            alt={"coupon"}
                          />
                          <div className={classes.couponCodeWrap}>
                            <div className={classes.couponCode}>
                              {`No. ${m.code}`}
                            </div>
                          </div>

                          <div className={classes.couponFooter}>
                            <div className={classes.couponContentFooter}>
                              {" "}
                              {`ใช้คูปองวันที่ ${humanExpireTime(m.now)}`}
                            </div>
                          </div>
                          <div className={classes.couponDetail}>
                            {isRunOut
                              ? "สิทธิ์ถูกใช้ครบแล้ว"
                              : "สุ่มไม่ได้ของรางวัล"}
                          </div>
                        </div>
                      );
                    }
                    if (notShow) {
                      return null;
                    }
                    return (
                      <div className={classes.couponItem} key={i}>
                        <a
                          className={`coupon__anchor ${expired ? "coupon__anchor--expired" : ""}`}
                          href={`/coupon-detail/${m.code}?branchId=${branchId}`}
                        >
                          <img
                            className="coupon__image"
                            src={m.assetrectangle}
                          />
                        </a>

                        <div className={classes.couponCodeWrap}>
                          <div className={classes.couponCode}>
                            {`No. ${m.code}`}
                          </div>
                        </div>

                        {m.activatedate !== "" && (
                          <div className={classes.couponFooter}>
                            <div className={classes.couponContentFooter}>
                              {" "}
                              {`ใช้คูปองวันที่ ${humanExpireTime(m.redeeminfo?.redeemcouponserviceuse)}`}
                            </div>
                            <div className={classes.couponExtraContentFooter}>
                              <div className={classes.button}>
                                {m.activatedate !== "" ? (
                                  <a
                                    onClick={() =>
                                      history.push(
                                        `/my-used-coupon-detail/${m.code}?branchId=${branchId}`,
                                      )
                                    }
                                    className={classes.btnFont}
                                  >
                                    ดูรายละเอียด
                                  </a>
                                ) : (
                                  <a className={classes.btnFont}>หมดอายุแล้ว</a>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>

        <Footer imageIndex={2} />
      </div>
    </>
  );
};

export default MyUsedCoupon;
