import React from "react";
import { encode, humanExpireTime } from "./helpper";
import AppBar from "../components/AppBar";
import Footer from "../components/Footer";
import clsx from "clsx";
import muiStyles from "./muiStyle";
import "../App.css";
import useLiff from "../hooks/useAuth";
import { isDealer } from "../hooks/api";
import { getMyCoupons, useAPIQuery } from "../hooks/queries";
import { useHistory } from "react-router-dom";
import { getBranchID } from "../hooks/user";

const MyNewCoupon = () => {
  const classes = muiStyles();
  const history = useHistory();
  const { getProfile, getAccessToken } = useLiff();
  const token = getAccessToken;
  const profile = getProfile;

  const branchId = getBranchID();
  const param = {
    accesstoken: token,
    lineuserid: profile.userId,
    ...(isDealer && { dealerid: branchId }),
  };
  const encodedData = encode(param);
  const myCoupons = useAPIQuery("myCoupons", () => getMyCoupons(encodedData));
  const couponList =
    myCoupons?.data?.items.filter(
      (item) => item.isGroupUsed === "false" && item.isExpired === "false",
    ) ?? [];

  return (
    <>
      <div className={classes.container}>
        <AppBar />

        <div className={classes.content}>
          <div className={classes.wrapper}>
            <div className={classes.heading}>
              <div className={classes.headingTab}>
                <a
                  onClick={() =>
                    history.push(`/my-new-coupon?branchId=${branchId}`)
                  }
                  className={clsx(
                    classes.headingAnchor,
                    classes.headingAnchorActive,
                  )}
                >
                  คูปองที่ใช้ได้
                </a>
                <a
                  onClick={() =>
                    history.push(`/my-used-coupon?branchId=${branchId}`)
                  }
                  className={classes.headingAnchor}
                >
                  คูปองที่ใช้แล้ว/หมดอายุ
                </a>
              </div>
            </div>

            {couponList === undefined || couponList.length === 0 ? (
              <div className={classes.coupon}>
                <div className={classes.empty}>
                  <div className={classes.emptyMsg}>ไม่มีคูปอง</div>
                </div>
              </div>
            ) : (
              <div className={classes.coupon}>
                <div className={classes.coupons}>
                  {couponList.map((m, i) => {
                    const expired =
                      m.activatedate !== "" ||
                      m.isExpired === "true" ||
                      m.isGroupUsed === "true" ||
                      m.titleen === "No Rewarded" ||
                      !m.isRunOut;
                    if (expired) {
                      return null;
                    }
                    return (
                      <div className={classes.couponItem} key={i}>
                        <img
                          className="coupon__image"
                          src={m.assetrectanglewithbutton}
                          alt="coupon__image"
                        />

                        <div className={classes.couponCodeWrap}>
                          <div className={classes.couponCode}>
                            {`No. ${m.code}`}
                          </div>
                        </div>

                        <div className={classes.couponFooter}>
                          <div className={classes.couponContentFooter}>
                            {m.activatedate !== ""
                              ? `ใช้คูปองวันที่ ${humanExpireTime(m.activatedate)}`
                              : m.isGroupUsed === "true"
                                ? "ไม่สามารถใช้สิทธิ์ได้"
                                : m.expiretime === ""
                                  ? "จนกว่าของพรีเมียมจะหมด"
                                  : `หมดเขต ${humanExpireTime(m.expiretime)}`}
                          </div>
                          <div className={classes.couponExtraContentFooter}>
                            <div className={classes.button}>
                              <a
                                className={classes.btnFont}
                                onClick={() =>
                                  history.push(
                                    m.type === "RewardRandom2022-2"
                                      ? `/coupon-detail-random/${m.itemid}?branchId=${branchId}`
                                      : `/coupon-detail/${m.itemid}?branchId=${branchId}`,
                                  )
                                }
                              >
                                กดใช้คูปอง
                              </a>
                            </div>
                          </div>
                        </div>

                        {m.isRunOut === "true" && (
                          <div className={classes.couponRunout}>
                            ใช้สิทธิ์ครบแล้ว
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>

        <Footer imageIndex={2} />
      </div>
    </>
  );
};

export default MyNewCoupon;
